import React, { Component } from 'react'
import urlParse from 'url-parse'
import { withRouter } from 'react-router-dom'
import { Div } from '@driftt/tide-core'
import LoadingPage from 'components/shared/LoadingPage'
import { transferUser, getTransferOrgName, hasMultiOrg }  from 'api/findmyteam'
import { setSnippets } from 'utils/snippets'
import config from 'config'
import RequestPermission from  './RequestPermission'

const STATUS_TYPE = {
  LOADING: 0,
  VERIFYING: 1
}

const redirectToLogin = () => {
  window.location.replace('/login')
}

const redirectToApp = (oneTimeCode) => {
  window.location.href = `${config.APP_ROOT}/home?code=${oneTimeCode}`
}

const parseQuery = (url) => {
  const shouldParseQuery = true
  return urlParse(url, shouldParseQuery).query
}

const parseTokenOrRedirect = (url) => {
  const token = parseQuery(url).token
  if (!token) {
    redirectToLogin()
  }
  return token
}

const parseEmail = (url) => {
  return parseQuery(url).email
}

class TransferUser extends Component {
  constructor(props) {
    super(props)
    this.state = {
      status: STATUS_TYPE.LOADING,
      token: parseTokenOrRedirect(this.props.location.search),
      email: parseEmail(this.props.location.search),
      error: ''
    }
  }

  componentDidMount() {
    const { token } = this.state

    setSnippets()
    hasMultiOrg(token)
      .then(() => {
        this.setState({ hasMultiOrg : true })
        this.onAcceptTransfer(token)
      })
      .catch(() => {
        this.setState({ status: STATUS_TYPE.VERIFYING })
      })
      getTransferOrgName(token)
        .then(orgName => this.setState({orgName}))
  }

  onAcceptTransfer = () => {
    const { token } = this.state
    return transferUser(token)
      .then(oneTimeCode => {
        redirectToApp(oneTimeCode)
      }).catch((err) => {
        this.setState({ error: 'Request Failed' })
        console.error(err)
      })
  }

  render() {
    const { status, email, error, orgName } = this.state
    return (
      <Div>
        { status === STATUS_TYPE.LOADING &&
          <LoadingPage />
        }
        { status === STATUS_TYPE.VERIFYING &&
          <RequestPermission
            error={error}
            email={email}
            onAcceptTransfer={this.onAcceptTransfer}
            orgName={orgName}
          />
        }
      </Div>
    )
  }
}

export default withRouter(TransferUser)
