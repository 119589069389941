import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import parseQueryParams from '../../../utils/parseQueryParams'
import API from '../../../api/auth'
import MessageConstants from '../../../constants/MessageConstants'
import { Button, H1, Form, P } from '@driftt/tide-core'
import PasswordHints from './PasswordHints'
import PasswordInput from './PasswordInput'
import config from 'config'

const SPECIAL_REGEX = /[@~`!#$%^&*+=\-[\]\\';,/{}|\\":<>?]/
const UPPERCASE_REGEX = /[A-Z]/
const LOWERCASE_REGEX = /[a-z]/
const NUMERIC_REGEX = /\d/

class ResetPassword extends Component {

  constructor(props) {
    super(props)

    this.state = {
      resetToken: null,
      password: '',
      error: '',
      configFetched: false,
      passwordConfig: {},
      loading: false,
      isAdminResetFlow: false,
      disableSubmit: false
    }
  }

  componentWillReceiveProps(nextProps) {
    this.redirectToForgot(nextProps)
  }

  componentWillMount() {
    this.redirectToForgot(this.props)
  }

  redirectToForgot = (props) => {
    const queryParams = parseQueryParams(props.location.search)
    if (!queryParams.token) props.history.push('/forgot-password')
    else this.setState({ resetToken: queryParams.token, isAdminResetFlow: !!queryParams.adminReset })
  }

  handleResponse = (response) => {
    if (response.status === 403) {
      this.props.history.push({ pathname:'/forgot-password', state: { error: MessageConstants.EXPIRED_TOKEN_RESET }})
    } else if (response.status === 401) {
      this.setState({ error: MessageConstants.INVALID_TOKEN, loading: false })
    } else if (response.status === 400){
      this.setState({
        error: "Your password is almost secure! Make sure that you meet all requirements.",
        configFetched: true,
        passwordConfig: JSON.parse(response.data.message),
        loading: false
      })
    } else if (response.status === 200) {
      this.setState({ message: MessageConstants.PASSWORD_RESET_SUCCESS, error: '', loading: false, disableSubmit: true }, () => {
        setTimeout(() => { window.location.href = config.LOGIN_ROOT }, 3000)
      })
    }
  }

  submitReset = (event) => {
    event.preventDefault()
    this.setState({loading: true}, () => API.resetPassword(this.state.password, this.state.resetToken, this.handleResponse))
  }

  handlePasswordInput = (event) => {
    this.setState({ password: event.target.value, error: undefined })
  }

  handlePasswordConfirm = (event) => {
    this.setState({ confirmPassword: event.target.value, error: undefined })
  }

  hasMinCharacters = password => {
    return password.length >= this.state.passwordConfig.minChar
  }

  hasMinUniqueCharacters = password =>  {
    return (new Set(password)).size >= this.state.passwordConfig.minUniqueChar
  }

  hasSpecialCharacter = password => {
    if (this.state.passwordConfig.reqSpecialChar) {
      return SPECIAL_REGEX.test(password)
    }
    return true;
  }

  hasMixedCase = password => {
    if (this.state.passwordConfig.reqMixedCase) {
      return UPPERCASE_REGEX.test(password) && LOWERCASE_REGEX.test(password)
    }
    return true;
  }

  hasNumber = password => {
    if (this.state.passwordConfig.reqNumber) {
      return NUMERIC_REGEX.test(password)
    }
    return true;
  }

  passwordsMatch = (password, passwordConfirm) => password === passwordConfirm

  meetsAllRequirements = (password, passwordConfirm) => {
      return this.hasMinCharacters(password) &&
        this.hasSpecialCharacter(password) &&
        this.hasMinUniqueCharacters(password) &&
        this.hasMixedCase(password) &&
        this.hasNumber(password) &&
        this.passwordsMatch(password, passwordConfirm)
    }

  canChange = (password, passwordConfirm) => {
    if (this.state.configFetched) {
      return this.meetsAllRequirements(password, passwordConfirm)
    } else {
      return this.passwordsMatch(password, passwordConfirm)
    }
  }

  render() {
    const {
      password,
      confirmPassword,
      error,
      message,
      configFetched,
      passwordConfig,
      loading,
      isAdminResetFlow,
      disableSubmit
    } = this.state
    const disabled = !this.canChange(password, confirmPassword) || disableSubmit
    return (
      <div className="forgot-password-page">
        <H1>{isAdminResetFlow ? "Create a new password" : "Reset password"}</H1>
        {isAdminResetFlow && <P className="admin-password-reset-context">Your Drift admin recently reset your password. Now create a new password that’s only known to you!</P>}
        <Form className="drift-form">
          <PasswordInput onChange={this.handlePasswordInput} value={password} placeholder="New password" />
          <PasswordInput onChange={this.handlePasswordConfirm} value={confirmPassword} placeholder="Re-enter your new password" />

          {configFetched && <PasswordHints
            hasMinCharacters={this.hasMinCharacters(password)}
                  hasMinUniqueCharacters={this.hasMinUniqueCharacters(password)}
                  hasSpecialCharacter={this.hasSpecialCharacter(password)}
                  hasMixedCase={this.hasMixedCase(password)}
                  hasNumber={this.hasNumber(password)}
                  passwordsMatch={this.passwordsMatch(password, confirmPassword)}
                  meetsAllRequirements={this.meetsAllRequirements(password, confirmPassword)}
                  passwordConfig={passwordConfig} /> }
          <Button size="large" onClick={this.submitReset} disabled={disabled} loading={loading}>Save password</Button>
          {message && <strong className="reset-password request-status success">{ message }</strong> }
          {error && <strong className="reset-password request-status error">{ error }</strong> }
        </Form>
      </div>
    )
  }
}

const ResetPasswordPage = withRouter(ResetPassword)
export default ResetPasswordPage
