import config from 'config'
import { omit } from 'ramda'
import parse from 'url-parse'

const DUMMY_CLIENT_ID = "AUTH_CLIENT_ID"
const CLIENT_ID_KEY = 'drift-auth-client-id'
const REDIRECT_URL_KEY = 'drift-auth-redirect-url'
const REDIRECT_URL_TIMESTAMP = 'drift-auth-redirect-set-time'

export const setRedirectInfo = (redirectUrl, clientId) => {
  if(!!clientId && clientId === DUMMY_CLIENT_ID) {
    return
  }
  if (redirectUrl) {
    const regionlessRedirectUrl = removeRegionParam(redirectUrl)
    window.localStorage.setItem(REDIRECT_URL_KEY, regionlessRedirectUrl)
  }
  clientId && window.localStorage.setItem(CLIENT_ID_KEY, clientId)
  !!(clientId || redirectUrl) && window.localStorage.setItem(REDIRECT_URL_TIMESTAMP, Date.now())
}

const removeRegionParam = (url) => {
  const parsedUrl = parse(url, true)
  const cleanQuery = omit(['region'], parsedUrl.query)
  const fixedUrl = parsedUrl.set('query', cleanQuery)
  return fixedUrl.href
}

export const getRedirectUrl = () => {
  const redirectUrl = window.localStorage.getItem(REDIRECT_URL_KEY)
  const redirectUrlSetTime = Number(window.localStorage.getItem(REDIRECT_URL_TIMESTAMP))

  return (!!redirectUrl && setTimeIsLessThanFiveMinAgo(redirectUrlSetTime))
    ? removeRegionParam(redirectUrl)
    : config.DEFAULT_REDIRECT_URL
}

export const getClientId = () => {
  const clientId = window.localStorage.getItem(CLIENT_ID_KEY)

  if(!!clientId && (clientId === DUMMY_CLIENT_ID || clientId === 'undefined')) {
    cleanUpRedirectInfo()
    return config.DEFAULT_CLIENT_ID
  }

  const redirectUrlSetTime = Number(window.localStorage.getItem(REDIRECT_URL_TIMESTAMP))

  return (!!clientId && setTimeIsLessThanFiveMinAgo(redirectUrlSetTime))
    ? clientId
    : config.DEFAULT_CLIENT_ID
}

export const cleanUpRedirectInfo = () => {
  window.localStorage.removeItem(CLIENT_ID_KEY)
  window.localStorage.removeItem(REDIRECT_URL_KEY)
  window.localStorage.removeItem(REDIRECT_URL_TIMESTAMP)
}

const setTimeIsLessThanFiveMinAgo = (setTime) => {
  const diff = Date.now() - setTime
  const FIVE_MIN = 5 * 60 * 1000
  return diff <= FIVE_MIN
}
