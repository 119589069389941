import React from 'react'
import ReactDOM from 'react-dom'
import LoadStyles from '@driftt/tide-core/dist/styles'
import Routes from './routes'
import './index.css'

ReactDOM.render(
  <Routes />,
  document.getElementById('root')
)
LoadStyles()
