import React,{ useEffect, useState } from 'react'
import urlParse from 'url-parse'
import { fetchSsoErrorRecord } from 'api/sso'
import { Div, H2, H5, CircleLoader } from '@driftt/tide-core'

import './style.css'

const DEFAULT_SOLUTION = "Something went wrong. Please try logging in again."

const SamlErrorInfo = ({ referenceId, solution = DEFAULT_SOLUTION }) => (
  <Div className="saml-error-info">
    <H2 className="saml-error-info-header">Login failed</H2>
    <Div className="saml-error-info-solution">{solution}</Div>
    {referenceId && <H5 className="saml-error-info-reference-id">{referenceId}</H5>}
  </Div>
)

const SamlErrorPage = () => {
  const [samlError, setSamlError] = useState(null)
  const [loading, setLoading] = useState(true)
  const [requestId, setRequestId] = useState(null)

  const handleSamlErrorFetch = (errorRecord) => setSamlError(() => errorRecord, setLoading(false))

  const handleSamlErrorFetchError = (error) => setLoading(() => false, console.error(error))
  
  useEffect(() => {
    const requestId = urlParse(window.location.search, true).query.requestId
    if (requestId) {
      setRequestId(() => requestId, 
        fetchSsoErrorRecord({ requestId })
          .then(handleSamlErrorFetch)
          .catch(handleSamlErrorFetchError)
      )
    } else {
      setLoading(false)
    }
  }, [])
  
  return (
    <Div className="saml-error-container" >
      {loading ? 
        <CircleLoader className="saml-error-loader" size="large"/> : 
        <SamlErrorInfo solution={samlError ? samlError.solution : undefined} referenceId={requestId} />
      }
    </Div>
  )
}

export default SamlErrorPage