import { isArray, each } from 'lodash'

export const encodeQueryData = function (data) {
  const ret = []
  for (const key in data) {
    const value = data[key]
    if (isArray(value)) {
      each(value, element =>
        ret.push(`${encodeURIComponent(key)}=${encodeURIComponent(element)}`)
      )
    } else if (value != null) {
      ret.push(`${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
    }
  }
  return ret.join('&')
}
