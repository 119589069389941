import React, { Component } from 'react'
import { Input, ToolTip } from '@driftt/tide-core';
import { Icon } from '@driftt/tide-icons'
import './styles.css'

class PasswordInput extends Component {
  constructor(props) {
    super(props)
    this.state = { displayPassword: false }
  }

  toggleHiddenPassword = () => {
    this.setState({ displayPassword: !this.state.displayPassword })
  }

  render() {
    const {
      value,
      placeholder,
      onChange,
      size = 'large',
    } = this.props

    const {
      displayPassword
    } = this.state

    const passwordDisplay = displayPassword ? "text" : "password"
    const passwordTooltipText = displayPassword ? "Hide Password" : "Show Password"

    return (
        <div className="password-input-wrapper">
          <Input
            placeholder={placeholder}
            value={value}
            type={passwordDisplay}
            size={size}
            className="password-input"
            onChange={onChange}
            required
          />
          <div className="password-input-preview" onClick={this.toggleHiddenPassword}>
            <ToolTip className="password-input-tooltip" flow="up" render={() => `${passwordTooltipText}`}>
              <Icon name={displayPassword ? "eye-off" : "eye"} />
            </ToolTip>
          </div>
        </div>
    )
  }
}
export default PasswordInput
