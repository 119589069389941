import React, { Component } from 'react'
import emailIcon from 'assets/svg/invitation-sent.svg'
import { withRouter } from 'react-router-dom'
import { Div, H1, P } from '@driftt/tide-core'
import { resendInvite } from 'api/invite'
import urlParse from 'url-parse'

import './style.css'

class ResendInvitation extends Component {
    constructor(props) {
        super(props)
        this.state = {
            errorOccurred: false
        }
    }

    sendInvite = (token, orgId, email) => {
        if(token && orgId && email){
            resendInvite(token, orgId, email)
            .catch(err => {
                console.error("Error occured while resending invite: " + err)
                this.setState({
                    errorOccurred: true
                })
            })
        }
        else{
            console.error("Not enough information provided to resend invitation")
            this.setState({
                errorOccurred: true
            })
        }
    } 

    componentDidMount () {
        const urlQueryParams = urlParse(this.props.location.search, true).query
        this.sendInvite(urlQueryParams.token, urlQueryParams.orgId, urlQueryParams.inviteEmail)
    }

    render () {
        const { email, errorOccurred } = this.state

        return(
            <Div className="resend-invitation-page">
                <Div className="resend-invitation-content-container">
                    <Div className="email-icon-container">
                        <img src={emailIcon} alt="Email sent" className="email-icon"/>
                    </Div>
                    {!errorOccurred ?
                        <Div>
                            <H1>Invitation sent!</H1>
                            <P>We re-sent the invitation to {email} to join your team on Drift! You can now close this window - happy Drifting!</P>
                        </Div>
                    :
                        <Div>
                            <H1>Failed to send the invitation...</H1>
                            <P>Please visit the teammates page within the Drift app to resend an invitation to {email}. Sorry for the inconvenience!</P>
                        </Div>
                    }
                </Div>
            </Div>
        )
    }
}

export default withRouter(ResendInvitation)
