import * as R from 'ramda'

import config from 'config'

const elementID = 'g-recaptcha'
const getRecaptchaElement = () => document.getElementById(elementID)

export const getToken = () => {
  try {
    return window.grecaptcha.getResponse()
  } catch(error) {
    console.error("Failed to get captcha token")
    return ''
  }
}

export const reset = () => {
  try {
    window.grecaptcha.reset()
  } catch(error) {
      console.error("Failed to reset captcha")
  }
}

export const initialize = (setSolvedCaptcha) => {
  try {
    window.grecaptcha.ready(() => {

      const element = getRecaptchaElement()

      // If element isn't empty, captcha has already been rendered.
      if (R.isEmpty(element.innerHTML)) {
        window.grecaptcha.render(element, {
          sitekey: config.CAPTCHA_SITE_KEY,
          size: 'normal',
          callback: () => setSolvedCaptcha(true),
          'expired-callback': () => setSolvedCaptcha(false)
        })
      }
    })
  } catch(error) {
      console.error("Failed to initialize captcha")
  }
}
