import React from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import AuthLayout from './components/layouts/Auth'

import LoginPage from './components/pages/Login'
import IosAppLanding from './components/pages/AppSplash/IosAppLanding'
import AndroidAppLanding from './components/pages/AppSplash/AndroidAppLanding'
import ForgotPasswordPage from './components/pages/ForgotPassword'
import ResetPasswordPage from './components/pages/ResetPassword'
import SamlErrorPage from './components/pages/SamlError'
import FindYourTeamEmailVerificationPage from './components/pages/FindYourTeam/EmailVerification'
import FindYourTeamJoinPage from './components/pages/FindYourTeam/JoinOrganization'
import TransferUser from './components/pages/TransferUser'
import AcceptRequest from './components/pages/AcceptRequest'
import ResendInvitation from './components/pages/ResendInvitation'
import InviteLinkCopied from './components/pages/InviteLinkCopied'

const Routes = () => (
  <BrowserRouter>
    <div className="layout-wrapper">
      <Switch>
        <Route exact path="/download-ios-app" component={IosAppLanding}/>
        <Route exact path="/download-android-app" component={AndroidAppLanding}/>
        <Route path="/findTeam/join" component={FindYourTeamJoinPage} />
        <Route path="/transferUser" component={TransferUser} />
        <Route path="/findTeam/acceptRequest" component={AcceptRequest} />
        <Route path="/resendInvite" component={ResendInvitation} />
        <Route path="/copyInviteLink" component={InviteLinkCopied} />
        <Route path="/">
          <AuthLayout>
            <Route exact path="/" component={LoginPage} />
            <Route path="/login" component={LoginPage} />
            <Route exact path="/forgot-password" component={ForgotPasswordPage} />
            <Route path="/reset" component={ResetPasswordPage} />
            <Route path="/findTeam/verifyEmail" component={FindYourTeamEmailVerificationPage} />
            <Route path="/saml/error" component={SamlErrorPage} />
          </AuthLayout>
        </Route>
      </Switch>
    </div>
  </BrowserRouter>
)

export default Routes
