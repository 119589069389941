import React from 'react'
import cx from 'classnames'
import { Div, Span, Ul } from '@driftt/tide-core'

import Bolt from './bolt'

import './style.css'

const Hint = ({ completed, children }) => (
  <li className={cx('hints', { completed })}><Bolt/>{children}</li>
)

const PasswordHints = ({
  hasMinCharacters,
  hasMinUniqueCharacters,
  hasSpecialCharacter,
  hasMixedCase,
  hasNumber,
  passwordsMatch,
  meetsAllRequirements,
  passwordConfig,
  onChange,
}) => {
  return (
    <Div className={cx('password-hints')}>
      { !meetsAllRequirements &&
        <Ul onLoad={() => onChange(false)}>
          <Hint completed={hasMinCharacters}>{passwordConfig.minChar} characters minimum</Hint>
          <Hint completed={hasMinUniqueCharacters}>{passwordConfig.minUniqueChar} unique characters</Hint>
          {passwordConfig.reqSpecialChar && <Hint completed={hasSpecialCharacter}>One special character</Hint>}
          {passwordConfig.reqNumber && <Hint completed={hasNumber}>One number</Hint>}
          {passwordConfig.reqMixedCase && <Hint completed={hasMixedCase}>Uppercase and lowercase</Hint>}
          <Hint completed={passwordsMatch}>Passwords match</Hint>
        </Ul>
      }
      { meetsAllRequirements &&
        <Div className="password-verification" onLoad={() => onChange(true)}>
          <Bolt/>
          <Span>Sweet! Your password is secure</Span>
        </Div>
      }
    </Div>
  )
}

export default PasswordHints
