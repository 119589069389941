import React from 'react'

const OAuthConstants = {}

const GENERIC_ERR_MESSAGE = () => {
  return (
    <p className="request-status error">
      Whoops! Something went wrong
    </p>
  )
}

const INVITED_USER_DENY = () => {
  return (
    <p className="request-status error">
      Your teammate has invited you to join Drift, follow the link in your inbox to activate your account!
    </p>
  )
}

const NO_ERROR = () => {
  return false
}

OAuthConstants.SUCCESS = 'Third-Party Login' //synced with LoginEvent.SUCCESS
OAuthConstants.NEW_USER_SIGNUP = 'Third-Party Sign Up' //synced with LoginEvent.NEW_USER_SIGNUP 
OAuthConstants.INVITED_USER_SIGNUP = 'Invited User Sign Up' //synced with LoginEvent.INVITED_USER_SIGNUP 
OAuthConstants.SSO_REDIRECT = 'Third-Party Login SSO Redirect' //synced with LoginEvent.SSO_REDIRECT 
OAuthConstants.INVITED_USER_SIGNUP_MISSED = 'Invited User Opportunity Missed' //synced with LoginEvent.INVITED_USER_SIGNUP_MISSED
OAuthConstants.PASSWORD_RESET_REQUIRED = "PasswordResetRequired"
OAuthConstants.INVALID_CAPTCHA_TOKEN = "Invalid captcha token"

OAuthConstants.GENERIC_ERR_MESSAGE = GENERIC_ERR_MESSAGE
OAuthConstants.INVITED_USER_DENY = INVITED_USER_DENY
OAuthConstants.NO_ERROR = NO_ERROR

export default OAuthConstants
