import React, { Component } from 'react'
import { Div, H1, H4, P, AsyncButton } from '@driftt/tide-core'
import driftLogo from 'assets/svg/logo.svg'
import contactCard from 'assets/svg/contact-card.svg'
import './styles.css'

class RequestPermission extends Component {

  componentDidMount() {

    window.analytics && window.analytics.track && window.analytics.track('User Accessed Transfer Page', {
      context: "existing user",
      email: this.props.email,
      anonymousId: !!window.analytics.user ? window.analytics.user().anonymousId() : 'none'
    })
  }

  onAcceptTransfer = () => {
    window.analytics && window.analytics.track && window.analytics.track('User Accepted From Transfer Page', {
      context: "existing user added",
      email: this.props.email,
      anonymousId: !!window.analytics.user ? window.analytics.user().anonymousId() : 'none'
    })
    this.props.onAcceptTransfer()
  }

  render() {
    const { email, orgName, error } = this.props
    return (
      <Div className="drift-transfer-app">
        <Div className="drift-transfer-container">
          <img src={driftLogo} alt="Drift" className="driftLogo" />
          <Div className="transfer-existing-user-image">
            <img src={contactCard} alt="contact" className="contactCardLogo" />
          </Div>
          <Div className="transfer-existing-user-content">
            <H1 className="transfer-existing-user-header">Click the button below to join {orgName} in Drift!</H1>
            <AsyncButton className="join-team-button" size="large" promise={async () => this.onAcceptTransfer()}>
              Join your team in Drift
            </AsyncButton>
            {error && <P className="transfer-error-message">{error}</P>}
            <H4 className="transfer-call-to-action">Heads up!</H4>
            <P className="transfer-existing-user-info">
                We found a free Drift account associated with the email <span className="light-blue-text">{email}</span>.
                Joining this organization will create a new account and delete all data associated with your free account.
            </P>
          </Div>
        </Div>
      </Div>
    )
  }
}

export default RequestPermission
