const parseQueryParams = (search) => {
  if (!search) return {}

	const values = search.slice(1).split('&').map((item) => { 
    if (item) { 
      const param = {}
      param[`${ item.split('=')[0] }`] = item.split('=')[1]
      return param
    }
    return null
  })

  return Object.assign({}, ...values)
}

export default parseQueryParams