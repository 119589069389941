import React from 'react'
import { H2 } from '@driftt/tide-core';
import { Icon } from '@driftt/tide-icons'
import './styles.css'

const LoadingPage = () => (
  <div className="app-loading-placeholder">
    <Icon name="drift-logo-round" width={100} height={100} />
    <H2>Just a moment&hellip;</H2>
  </div>
)

export default LoadingPage
