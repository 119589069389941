const MessageConstants = {}

MessageConstants.EXPIRED_TOKEN_RESET = 'Sorry! This link has expired to keep your account secure. Just type in the email you use to sign in with Drift, and we’ll send you a new link!'
MessageConstants.EXPIRED_TOKEN_ACCEPT = 'Sorry! This link has expired or has already been used.'
MessageConstants.DEFAULT_SERVER_ERROR = 'Something went wrong, please try again.'
MessageConstants.INVALID_TOKEN = 'You have an invalid token.'
MessageConstants.LOGIN_BAD_INPUT = 'Sorry, you entered an incorrect email address or password.'
MessageConstants.CAPTCHA_TOKEN_EXPIRED = 'Sorry, your login request expired. Please complete the captcha below and try again.'
MessageConstants.SENT_PASSWORD_RESET = 'Look out for an email from us with instructions on how to reset your password!'
MessageConstants.FORGOT_HELPER = 'To reset your password, enter the email that you use to sign in to Drift.'
MessageConstants.PASSWORD_RESET_SUCCESS = 'Your password has been changed successfully! You will be redirected to the login page momentarily.'
MessageConstants.USER_NOT_FOUND = 'Sorry! The user associated with this link was not found. Contact your admin for more details.'
MessageConstants.EMAIL_VERIFICATION_SUCCESS = "Email address has been successfully changed!"
MessageConstants.EMAIL_VERIFICATION_EMAIL_IN_USE_API_ERROR = "Email already in use."
MessageConstants.EMAIL_VERIFICATION_TOKEN_EXPIRED_API_ERROR = "Email update request not found"
MessageConstants.EMAIL_VERIFICATION_EMAIL_IN_USE_ERROR = "Email address has not been updated because the proposed email address is already in use. Please use your original email address to sign in."
MessageConstants.EMAIL_VERIFICATION_TOKEN_EXPIRED_ERROR = "Email address has not been updated because the time window to complete the email verification has expired. Please use your original email address to log in."
MessageConstants.EMAIL_VERIFICATION_GENERIC_FAILURE = "Failed to change email address. Please log in with your original email address."

export default MessageConstants
